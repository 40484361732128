import React from 'react'
import { Navigation } from '../../common/navigation'
import { GlobalHelmet } from '../../common/seo/globalHelmet'
import { CMSWrapper } from '../../components/cmsWrapper'
import { AREA_CARE_PATH } from '../../config/constants'
import { Button } from '../../common/button'

export default function Spielen() {
  const [currentQuestion, setCurrentQuestion] = React.useState(
    typeof window !== 'undefined'
      ? window.localStorage.getItem('current-question') || ''
      : ''
  )

  const delURL = () => {
    setCurrentQuestion('')
    window.localStorage.removeItem('JukeboxTop10')
    window.localStorage.removeItem('current-question')
    window.localStorage.removeItem('JukeboxAnswerValues')
  }

  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - Spielen" />
      <main>
        <Navigation />
        <header className="bg-img-red mb-0">
          <div className="col-md-6 offset-md-3 col-10 offset-1 text-center">
            <img
              src="/img/handlettering/handlettering-wer-was-wo-willst-du-morgen-sein.svg"
              alt="Wer, was, wo willst du morgen sein?"
              width="400"
            />
          </div>

          {currentQuestion === '' ? (
            <div id="start" className="row align-items-center mt-5">
              <div className="col-md-6 offset-md-3 col-12 text-center">
                <Button
                  color="red"
                  href={`${AREA_CARE_PATH}/spielen/frage-1`}
                  className="mb-3"
                >
                  Matchen
                </Button>
                <p>
                  Beantworte Fragen zum Thema Patient:innen, Aufgaben, zur
                  Selbsteinschätzung und zum Team. Am Ende eines jeden
                  Frageblocks kannst du je zwei Themen auswählen, die dir
                  besonders wichtig sind. So bekommst du am Ende die Stationen
                  des UKE angezeigt, die gut zu dir passen.
                  <br /> Viel Spaß!
                </p>
              </div>
            </div>
          ) : (
            <>
              {currentQuestion.includes('/gratulation') ? (
                <>
                  <div id="restart" className="row align-items-center mt-5">
                    <div className="col-md-6 offset-md-3 col-10 offset-1 text-center">
                      <Button
                        color="red"
                        href={`${AREA_CARE_PATH}/spielen/frage-1`}
                        className="mb-3"
                        onClick={delURL}
                      >
                        Nochmal spielen
                      </Button>
                      <p>
                        ....um andere Antworten zu geben und ganz neue Stationen
                        zu entdecken.
                      </p>
                    </div>
                  </div>
                  <div id="result" className="row align-items-center mt-5">
                    <div className="col-md-6 offset-md-3 col-10 offset-1 text-center">
                      <Button
                        color="dark-blue"
                        href={`${AREA_CARE_PATH}/testergebnis`}
                        className="mb-3"
                        onClick={delURL}
                      >
                        Zum Ergebnis
                      </Button>
                      <p>
                        ....und deine persönlichen Top 10 Stationen entdecken.
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div id="continue" className="row align-items-center mt-5">
                    <div className="col-md-6 offset-md-3 col-10 offset-1 text-center">
                      <Button
                        color="red"
                        href={currentQuestion}
                        className="mb-3"
                        onClick={delURL}
                      >
                        Weiterspielen
                      </Button>
                      <p>
                        ...und die restlichen der 35 Fragen zu beantworten, um
                        deine Top Ten herauszufinden.
                      </p>
                    </div>
                  </div>

                  <div id="delete" className="row align-items-center mt-5">
                    <div className="col-md-6 offset-md-3 col-10 offset-1 text-center">
                      <Button
                        color="dark-blue"
                        className="mb-2"
                        onClick={delURL}
                      >
                        Löschen
                      </Button>
                      <p className="mt-2">
                        um all deine Antworten zurückzusetzen
                        <br /> und von vorne zu beginnen
                      </p>
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          <div className="row align-items-center">
            <div className="col-6 offset-6 text-center">
              <img
                id="illustration-matching"
                className="m-0"
                src="/img/illustration-matching.png"
                height=""
                width="290"
                alt="Illustration"
              />
            </div>
          </div>
        </header>
      </main>
    </CMSWrapper>
  )
}
